import React, { useState, useEffect, useContext } from "react";
import NorthstarPageLayout from "../NorthstarPageLayout";
import HelpButtonContext from "../../contexts/helpContext";
import helpButtonText from "../../contexts/helpButtonText";
import northstarClient from "../../services/northstarClient";

const EmbeddedApplication: React.FC = () => {
    const [viewUrl, setViewUrl] = useState("")
    const [viewName, setViewName] = useState("")

    const {updateHelpContext} = useContext(HelpButtonContext);
    const GATSBY_API_URL = process.env.GATSBY_API_URL;

    useEffect(() => {
        updateHelpContext(helpButtonText.gtmModule);

        const queryParams = new URLSearchParams(window.location.search)

        const id = decodeURIComponent(queryParams.get("id") || "");

        const fetch = async () => {
            const response = await northstarClient.get(
                `${GATSBY_API_URL}/api/embedded-views/${id}`
            );

            const url = new URL(response.data.attributes.url);

            url.searchParams.append(
                "token",
                response.data?.attributes?.jwt,
            );

            setViewName(response.data.attributes.name);
            setViewUrl(url.toString());
        }

        fetch();
      }, []);

   return (
    <>
        <NorthstarPageLayout title={"View - " + viewName}>
            <div className="flex w-full h-full">
                <iframe
                    src={viewUrl}
                    className="grow content-stretch"
                >
                </iframe>
            </div>
        </NorthstarPageLayout>
</>
   )
}

export default EmbeddedApplication
